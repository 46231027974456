.sliding-text-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.sliding-text-wrapper {
  display: flex;
}

.sliding-text {
  display: flex;
  align-items: center;
  animation: slide 10s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
