@font-face{
  font-family: 'Satoshi';
  src: url('../public/Satoshi-Variable.ttf');
}

.Satoshi{
  font-family: Satoshi;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.word-selected {
  color: #013872;
}

.image-aluminum {
  width: 300px;
  border-radius: 14px;
  margin-bottom:140px;
  margin-left: 60px
}

.image-steel {
  width: 300px;
  border-radius: 14px;
  margin-bottom:130px;
  margin-left: 60px;
  background:grey;
}

.image-reformer-tube {
  
  width: 300px;
  height: 200px;
  border-radius: 14px;
  object-position: cover;
  margin-bottom:140px;
  margin-left: 60px;
}

.image-lubricant{

  background:#FFFAB5;
  width: 300px;
  border-radius: 14px;
  margin-bottom:140px;
  margin-left: 60px;
}

.image-eor{
  width: 300px;
  border-radius: 14px;
  margin-bottom:140px;
  margin-left: 60px;
}

.image-paints{
  width: 300px;
  border-radius: 14px;
  margin-bottom:140px;
  margin-left: 60px;
}

.word-selected:hover + .vertical-line {

  display:block; 

}

body::-webkit-scrollbar {
  width: 0px;
}
